<template>
  <div>
    <v-banner>
      KELOLA UNIT KERJA
      <template v-slot:actions>
        <v-btn color="primary" text @click="add()"> Tambah Unit Kerja </v-btn>
      </template>
    </v-banner>

    <div class="mt-5">
      <CSlider />
    </div>

    <v-divider class="my-3"></v-divider>

    <div class="mt-5">
      <v-card class="px-2">
        <v-card-title class="subtitle-2">
          <v-icon left color="primary">mdi-chart-box</v-icon>
          Statistik Unit Kerja
        </v-card-title>

        <CchartUK />
      </v-card>
    </div>
  </div>
</template>

<script>
import CSlider from "@/components/dashboard/unitkerja/slider";
import CchartUK from "@/components/dashboard/unitkerja/chart/statistik-UK";

import Sdetail from "@/store/dashboard/unitkerja/detail";

export default {
  components: {
    CSlider,
    CchartUK,
  },

  computed: {
    detail: {
      get() {
        return Sdetail.state.detail;
      },

      set(value) {
        Sdetail.commit("toggle", value);
      },
    },
  },

  mounted() {},

  methods: {
    add() {
      this.$router.push("/unitkerja/add").catch(() => {});
    },
  },
};
</script>